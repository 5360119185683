import React from 'react'
import {useSelector} from 'react-redux'

var theFUckinPage = [];

const YourSubmission = () => {
    const state = useSelector(state => state)

    const submitIt = (items, candyprops) => {

        // populating 'data' array with easier data values
        var data = []
        data.push(items.electionDate);
        data.push(items.lastDay);
        data.push(items.jurisdiction);
        data.push(items.area);
        data.push(items.title);

        //checking for unfilled data fields
        for (var j=0; j < data.length; j++) {
            if (data[j] === "") {
                console.log('No empty data plz')
                return;
            }
        }
        
        // filling out 'items' array with candidate/proposal data
        var JSON_Data = candyprops
        
        var JSON_Query = {
            "elect-date":data[0].split('"')[1].split('T')[0],
            "reg-date":data[1].split('"')[1].split('T')[0],
            "jurisdiction":data[2],
            "area":data[3],
            "title":data[4],
            "JSON_Data":JSON_Data
        }
        console.log(JSON.stringify(JSON_Query))

        if (true) {
            fetch('/api/add_data/', {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(JSON_Query)
            })
                .then(res => res.json())
                .then(response => console.log(response))
                .then(() => {
                    console.log(JSON_Query)
                    var newHeader = document.createElement('h2');
                    newHeader.innerHTML = 'Thanks for your submission!'
                    newHeader.style.color = 'green'
                    document.getElementById('main-shit').innerHTML = ''
                    document.getElementById('main-shit').appendChild(newHeader);
                });
                //.then(dates => fetch('/api/dates/' + dates))
        }
    }

        console.log(state)
        var items = state
        if (items === undefined || items === null) {
            return;
        }
        var return_elements = [];
        var candyProps = []
        var inlineStyle = {
            display: 'inline-block',
            marginBottom: '0px'
        }
        // building candidate/proposal data
        var tempJSON = items.proposalDescription;
            if (tempJSON.type === "candidates") {
                var candyBag = []
                for (var candyGuy in tempJSON.items) {
                    candyBag.push(
                        <div style={{textIndent: '2vw'}} key={candyGuy}>
                            <h4 style={inlineStyle}>Name:</h4>
                            <> {tempJSON.items[candyGuy].name}</>
                        </div>,
                        <div style={{textIndent: '4vw'}} key={candyGuy+100}>
                            <h4 style={inlineStyle}>Party:</h4>
                            <> {tempJSON.items[candyGuy].party}</>
                        </div>,
                        <hr style={{marginLeft: '3vw'}}></hr>
                    )
                }
                candyProps = [
                    <div>
                        <h3 style={inlineStyle}>Candidates: </h3>
                        {candyBag}
                    </div>
                ]
            }
            else {
                candyProps = [
                    <div>
                        <h3 style={inlineStyle}>Proposal Description: </h3>
                        {tempJSON.description}
                        <hr></hr>
                    </div>
                ]
            }

            // pushing all data
            return_elements.push(
                <div className="approval">
                    <h2>
                        Does you want this shit?
                    </h2>

                    <div className="submissionHolder">
                        <div>
                            <h3 style={inlineStyle}>Election Date: </h3>{items.electionDate.split('"')[1].split('T')[0]}
                        </div>
                        <hr></hr>
                        <div>
                            <h3 style={inlineStyle}>Last Day to Register: </h3>{items.lastDay.split('"')[1].split('T')[0]}
                        </div>
                        <hr></hr>
                        <div>
                            <h3 style={inlineStyle}>Jurisdiction: </h3>{items.jurisdiction}
                        </div>
                        <hr></hr>
                        <div>
                            <h3 style={inlineStyle}>Area: </h3>{items.area}
                        </div>
                        <hr></hr>
                        <div>
                            <h3 style={inlineStyle}>Title: </h3>{items.title}
                        </div>
                        <hr></hr>
                        {candyProps[0]}
                        <button className="approve" id={'approve'} onClick={() => submitIt(items, tempJSON)}>
                            Approve
                        </button>
                        <button className="deny" id={'deny'}>
                            Deny
                        </button>
                    </div>
                </div>
            )
            /*var x = document.getElementById('approve_0')
            x.onlick = function(e){
            }*/
        //}
        //this.setState({page_elements: return_elements})
        theFUckinPage = return_elements;
        console.log(items)

    return(
        <div id="main-shit">
            {theFUckinPage}
        </div>
    );
}
export default YourSubmission;