import React from 'react';
import { useForm } from 'react-hook-form';
import { withRouter} from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import { chooseLastDay } from './rootSlice';



var today = new Date()
var thisDay = (today.getYear() + 1900) + '-'
if (today.getMonth() + 1 < 10) {
    thisDay = thisDay + '0' + (today.getMonth() + 1) + '-';
}
else {
    thisDay = thisDay + (today.getMonth() + 1) + '-';
}
if (today.getDate() + 1 < 10) {
    thisDay = thisDay + '0' + (today.getDate() + 1);
}
else {
    thisDay = thisDay + (today.getDate() + 1);
}

const schema = yup.object().shape({

    lastDay: yup.date().min(thisDay).required(),


  });

const InputRegisterDate = props => {

    const dispatch = useDispatch()
    const history = useHistory()
    const lastDayData = useSelector(state => state.lastDay)
    

    const { register, handleSubmit, errors} = useForm({
        defaultValues: {lastDayData},
        resolver: yupResolver(schema),
        reValidateMode: 'onChange'
    });

    const onSubmit = (data) => {
    dispatch(chooseLastDay(JSON.stringify(data.lastDay)))
    history.push("./InputJurisdiction")
    console.log(data)
}
  /*const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });
  const onSubmit = data => {
    console.log('hit');
    action(data);
    console.log(data);
    props.history.push("./InputRegisterDate");
  }*/


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
            <h2>What is the last day to register?</h2>
                <input 
                name="lastDay"
                style={ errors.lastDay && errorStyle}
                ref={register}/>
                <input type="submit"/>
    </form>
  );
};

const errorStyle = {
    borderColor: 'red',
    borderStyle: 'solid',
}

export default withRouter(InputRegisterDate);