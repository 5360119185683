import React from 'react';
import { BrowserRouter as Router, Route} from 'react-router-dom'

import './App.css';
import Inputinfo from './components/customers/Inputinfo';
import Inputinfo2 from './components/customers/Inputinfo2';
import Header from './components/customers/Header';
import DateHolder from './components/customers/DateHolder';
import Elections from './components/customers/Elections';
import Election from './components/customers/Election';
import Candidate from './components/customers/Candidate';
import Submissions from './components/customers/Submissions';
import InputDate from './components/customers/inputs/InputDate';
import InputRegisterDate from './components/customers/inputs/InputRegisterDate';
import Login from './components/customers/Login';
import Register from './components/customers/Register';
import ReviewUsers from './components/customers/ReviewUsers';











function App() {

  return (
    <>
    <Router>
    <div className="App">

      <Header />

      <div className="main">

      <Route exact path="/" render={props => (
        <React.Fragment>
      <DateHolder />
        </React.Fragment>
      )}>
      </Route>

      <Route path="/Inputinfo" component={Inputinfo}>
        
      </Route>

      <Route path="/DateHolder" component={DateHolder}>
        
      </Route>

      <Route path="/Elections" component={Elections}>
        
        </Route>

        <Route path="/Election" component={Election}>
        
        </Route>

        <Route path="/Candidate" component={Candidate}>
        
        </Route>

        <Route path="/Submissions" component={Submissions}>
        
        </Route>

        <Route path="/Login" component={Login}>

</Route>

        <Route path="/Register" component={Register}>

        </Route>

        {/*<Route path="/ReviewUsers" component={ReviewUsers}>

      </Route>*/}

        <Route path="/Inputinfo2" component={Inputinfo2}>
        
        </Route>

        <Route path="/inputs/InputDate" component={InputDate}>

        </Route>

        

        <Route path="/inputs/InputRegisterDate" component={InputRegisterDate}>

</Route>

      </div>

      </div>


  
    </Router>
    </>
  );
}

export default App;
