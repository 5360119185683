import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Spring } from 'react-spring/renderprops';


export default class Election extends Component {
    render() {

        var CANDIES = JSON.parse(this.props.location.state.name.JSON_Data).items


        var items = []

        var i=0
        for (var candy in CANDIES) {
            i++;
            items.push([
                <Link key={i} to={{
                    pathname:"/Candidate",
                    state:{
                        address: this.props.location.state.address,
                        name: CANDIES[candy]
                    }
            }}>
                <div className="candidate-Holder">
                    <div>
                        <h2>{CANDIES[candy].name}</h2>
                        <p>{CANDIES[candy].party}</p>
                    </div>
                </div>
            </Link>
            ])
        }

        return (
            <div>

                    <Spring
                    from={{ marginTop: -500}}
                    to={{ marginTop: 0}}
                    duration={{duration: 1000}}
                    >
                    
                    {props =>(
                        <div style={props}>

                <div className="ElectionTopPart">
                    <h1>{this.props.location.state.name.Title}</h1>
                    {(JSON.parse(this.props.location.state.name.JSON_Data).description)}
                </div>


                    </div>
                    )}

                    </Spring>

                    <Spring
                    from={{ opacity: 0}}
                    to={{ opacity: 1}}
                    duration={{duration: 1000}}
                    >
                    
                    {props =>(
                        <div style={props}>

                            {items}


                    </div>
                    )}

                    </Spring>

            </div>
        )
    }
}
