import { createSlice } from '@reduxjs/toolkit'

const rootSlice = createSlice({
    name:"root",
    initialState:{
        electionDate:"",
        lastDay:"",
        jurisdiction:"",
        area:"",
        title:"",
        electOrProp:"",
        proposalDescription:""
    },

    reducers:{
        chooseElectionDate: (state, action) => {state.electionDate = action.payload},
        chooseLastDay: (state, action) => {state.lastDay = action.payload},
        chooseJurisdiction: (state, action) => {state.jurisdiction = action.payload},
        chooseArea: (state, action) => {state.area = action.payload},
        chooseTitle: (state, action) => {state.title = action.payload},
        chooseElectOrProp: (state, action) => {state.electOrProp = action.payload},
        chooseProposalDescription: (state, action) => {state.proposalDescription = action.payload},
        chooseCandidateDescription: (state, action) => {state.proposalDescription = action.payload},


    }

})

export const reducer = rootSlice.reducer;
export const {chooseElectionDate, chooseLastDay, chooseJurisdiction, chooseArea, chooseTitle, chooseElectOrProp, chooseProposalDescription, chooseCandidateDescription} = rootSlice.actions;