import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Spring } from 'react-spring/renderprops';



export class elections extends Component {

    constructor() {
        super();
        this.state = {
            electies: []
        }
    }

    static propTypes = {

    }

    componentDidMount() {
        console.log(this.props.location.state)
        var my_query = this.props.location.state;
        if (my_query != null) {
            fetch('/api/customers/' + JSON.stringify(my_query))
                .then(res => res.json())
                .then(electies => this.setState({electies}))
        }
    }

    printElections(electies) {
        var jurisdictions = {
            "country":"Country",
            "state":"State",
            "district_court": "District Court",
            "council_district": "Council District",
            "sldl": "State House district",
            "county": "County",
            "place": "Place",
            "sldu": "State Senate district",
            "cd": "Congressional district"
        }

        if (this.state.electies !==  undefined && this.state.electies.electies !== undefined) {
            var items = [];

            for (var i = 0; i < electies.length; i++) {
                items.push(
                <Link key={i} to={{
                        pathname:"/Election",
                        state:{
                            address: this.props.location.state.address,
                            name: electies[i]
                        }
                }}>
                    <div className="election-Holder">
                        <div><h2>{electies[i].Title}</h2></div>

                        <div> 
                        </div>

                            <div className="electionInfo">
                                <div>
                                {jurisdictions[electies[i].Jurisdiction]}
                                </div>
                                <div>
                                {electies[i].Area.toUpperCase()}
                                </div>

                            </div>

                            <div>
                            
                            </div>
                    </div>
                </Link>
                )
            }
            return(items)
        }
    }

    printTime() {
        var months = ["January","February","March","April","May","June","July","August","September","October","November","Devember"]

        if (this.state.electies !==  undefined && this.state.electies.dates !== undefined) {
            return "" + months[this.state.electies.dates.election_date.month - 1] + ' ' + this.state.electies.dates.election_date.date + ', ' + this.state.electies.dates.election_date.year;
        }
        else return
    }

    render() {
            return (
                <div>

                    <Spring
                    from={{ marginTop: -500}}
                    to={{ marginTop: 0}}
                    duration={{duration: 1000}}
                    >
                    
                    {props =>(
                        <div style={props}>

                    <div className="topPart">

                    <h1>{this.printTime()}</h1>

                    </div>


                    </div>
                    )}

                    </Spring>

                    <Spring
                    from={{ opacity: 0}}
                    to={{ opacity: 1}}
                    duration={{duration: 1000}}
                    >
                    
                    {props =>(
                        <div style={props}>

                    {this.printElections(this.state.electies.electies)}


                    </div>
                    )}

                    </Spring>
                                     
                </div>
            );
    }
}

export default elections
