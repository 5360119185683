import React from 'react'
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom'



export default function Login() {

    const { register, handleSubmit } = useForm();

    function getLogStatus() {
        fetch('/api/isLoggedIn')
        .then((res) => res.json())
        .then((res) => {
            console.log(res)
        })
    }

    const onSubmit = data => {
        fetch('/api/login/', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        .then((res) => res.json())
        .then((result) => {
            if (result.success) {
                Promise.resolve()
                .then(() => {
                    if (result.success === true) {
                        window.location = '/'
                    }
                })
            }
            console.log(result)
        });
    };
    /*const onSubmit2 = data => {
        fetch('/api/create_login/', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        .then((res) => res.json())
        .then((result) => {
            console.log(result)
        })
    };*/

    return (
        <div onClick={() => getLogStatus()}>
            <div className="login-Holder">

            <h1>log in</h1>

            <form onSubmit={handleSubmit(onSubmit)}>

            <input name="username" placeholder="username" ref={register} />
            <input name="password" placeholder="password" type="password" ref={register} />
            <input type="submit" />
            </form>


            <br></br>

            <div>

            Don't have an account?
            <p><Link to="/Register">Register</Link>
</p>
            
            </div>

            </div>


         
            
        </div>
    )
}