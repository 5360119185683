import React, { Component } from 'react'

export default class Inputinfo extends Component {
    constructor() {
        super()
        this.state = {
            candies: [],
            form: [],
            type: [],
            response: []
        }
    }

    addShit(type, num, deets) {
        var temp = this.state.candies;
        if (type === 0) {
            temp.push([
                <div key={num}>
                    <h3 >Candidate {num}</h3>
                    <div name="deets">{deets[0]}</div>
                    <div name="deets1">{deets[1]}</div>
                </div>
            ])
        }
        else {
            temp.push([
                <div>
                    <h3 >Proposal {num}</h3>
                    <div name="deets">{deets[0]}</div>
                    <div name="deets1">{deets[1]}</div>
                </div>
            ])
        }
        this.setState({candies: temp})
        this.toggleForm(type)
    }

    toggleForm(type) {
        this.setState({type})
        var num = this.state.candies.length + 1;
        if (type===0) {
            this.setState({form: (
                <div>
                    <h2>What is the name of candidate #{num}?</h2>
                    <input id={'item1.' + num.toString()} required type='text'></input>
                    <h2>What is the party of candidate #{num}?</h2>
                    <input id={'item2.' + num.toString()} required type='text'></input>
                    <br/>
                    <button onClick={() => this.addShit(type, num, [document.getElementById('item1.' + num.toString()).value, document.getElementById('item2.' + num.toString()).value])}>IM A BUTTON</button>
                </div>
            )})
        }
        else {
            this.setState({form: (
                <div>
                    <h2>What is the description of this proposal?</h2>
                    <input id={'item1.' + num.toString()} required type='text'></input>
                    <br/>
                </div>
            )})
        }
    }

    submit() {

        // populating 'data' array with easier data values
        var data = []
        data.push(document.getElementById('elect-date').value);
        data.push(document.getElementById('reg-date').value);
        data.push(document.getElementById('jurisdiction').value);
        data.push(document.getElementById('area').value);
        data.push(document.getElementById('title').value);

        //checking for unfilled data fields
        for (var j=0; j < data.length; j++) {
            if (data[j] === "") {
                console.log('No empty data plz')
                return;
            }
        }
        
        // filling out 'items' array with candidate/proposal data
        var type;
        var items = [];
        var JSON_Data;
        var i = 1
        var candies = this.state.candies;
        var temp;
        console.log(candies.length)
        console.log(i)
        if (this.state.type === 0) {
            type = 'candidates';
            for (i=0; i < candies.length; i++) {
                temp = candies[i][0].props.children;
                console.log(temp)
                items.push({
                    "name":temp[1].props.children,
                    "party":temp[2].props.children
                })
            }
            // building 'JSON_Data' JSON object with 'items' array data
            console.log(candies)
            console.log(items)
            JSON_Data = {
                "type":type,
                "items":items
            }
        }
        else {
            type = 'proposals';
            JSON_Data = {
                "type":type,
                "description":document.getElementById('item1.1').value
            }
        }
        
        var JSON_Query = {
            "elect-date":data[0],
            "reg-date":data[1],
            "jurisdiction":data[2],
            "area":data[3],
            "title":data[4],
            "JSON_Data":JSON_Data
        }
        console.log(JSON_Query)

        if (true) {
            fetch('/api/add_data/' + JSON.stringify(JSON_Query))
                .then(res => res.json())
                .then(response => this.setState({response}));
                //.then(dates => fetch('/api/dates/' + dates))
        }
    }

    maxDay() {
        var max = document.getElementById('elect-date');
        console.log(max)
        if (max === null) {
            return 
        }
        else {
            return max.value
        }
    }

    render () {
        var today = new Date()
        var thisDay = (today.getYear() + 1900) + '-'
        if (today.getMonth() + 1 < 10) {
            thisDay = thisDay + '0' + (today.getMonth() + 1) + '-';
        }
        else {
            thisDay = thisDay + (today.getMonth() + 1) + '-';
        }
        if (today.getDate() + 1 < 10) {
            thisDay = thisDay + '0' + (today.getDate() + 1);
        }
        else {
            thisDay = thisDay + (today.getDate() + 1);
        }

        return (
            <div >
                
                <h2>What date does this election take place on?</h2>
                <input id="elect-date" placeholder='mm/dd/yyyy' required type={'date'} min={thisDay} ></input>
                <hr></hr>
                <h2>What is the last day to register for this election?</h2>
                <input id="reg-date" placeholder='mm/dd/yyyy' required type={'date'} ></input>
                <hr></hr>
                <h2>What is the jurisdiction of the election?</h2>
                <input id="jurisdiction" required type={'text'}></input>

                <h2>What is the area of the election?</h2>
                <input id="area" required type={'text'} ></input>
                <hr></hr>
                <h2>What is type or election is this?</h2>
                <input onClick={() => this.toggleForm(0)} type="radio" id="candidates" name="gender" value="candidates"/>
                <label htmlFor="candidates">Race</label>
                <input onClick={() => this.toggleForm(1)} type="radio" id="proposals" name="gender" value="proposals"/>
                <label htmlFor="proposals">Proposals</label>
                <hr></hr>
                <h2>What is the title of the race/proposal?</h2>
                <input id="title" required type={'text'} ></input>
                <hr></hr>
                {this.state.form}

                <div>
                    {this.state.candies}
                </div>

                <button className={'lookUpButton'} onClick={() => this.submit()} >sumbit</button>

            </div>
        )
    }
}
