import React, { Component } from 'react'
import { Spring } from 'react-spring/renderprops';


export default class Candidate extends Component {
    render() {
        var data = this.props.location.state;
        return (
            <div>

                    <Spring
                    from={{ marginTop: -500}}
                    to={{ marginTop: 0}}
                    duration={{duration: 1000}}
                    >
                        
                        {props =>(
                            <div style={props}>


                        <div className="topPart">
                        
                        <h1>{data.name.name}</h1>
                        <div>{data.name.party}</div>
        
                        </div>

                        </div>
                        )}

                    </Spring>


                
            </div>
        )
    }
}
