import React from 'react';
import { useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import { chooseElectOrProp } from './rootSlice';

const ElectOrProp = props => {

    const dispatch = useDispatch()
    const history = useHistory()
    
    const submitValues = (value) => {
        dispatch(chooseElectOrProp(value));
        if (value === "proposals") {
            history.push("./InputProposal")
        }
        else {
            history.push("./InputCandidates")
        }
    }
  /*const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });
  const onSubmit = data => {
    console.log('hit');
    action(data);
    console.log(data);
    props.history.push("./InputRegisterDate");
  }*/


  return (
    <form>
            <h2>What is the title of this race?</h2>
                <button name="candidate" onClick={() => submitValues('candidates')}>candidate</button>
                <button name="proposal" onClick={() => submitValues('proposals')}>proposal</button>
    </form>
  );
};

export default ElectOrProp;