import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import { chooseCandidateDescription } from './rootSlice';


var items = [];
var page_elements = [];

const schema = yup.object().shape({
    candidateName: yup.string().required(),
    candidateDescription: yup.string().required(),
  });

const InputCandidates = props => {

    const dispatch = useDispatch()
    const history = useHistory()
    const inputCandidateData = useSelector(state => state.candidateDescription)
    

    const { register, handleSubmit, errors} = useForm({
      defaultValues: {inputCandidateData},
      resolver: yupResolver(schema),
      reValidateMode: 'onChange'
    });

    const submit = () => {
        console.log(items)
        var JSON_Data = {
            "type":"candidates",
            "items":items
        }
        dispatch(chooseCandidateDescription(JSON_Data))
        history.push("./YourSubmission")
    }

    const addUser = (data) => {
        console.log(items)
        items.push(
            {
                "name":data.candidateName,
                "party":data.candidateDescription
            }
        )
        page_elements.push(
            <div>
                <h2>{data.candidateName}</h2>
                <p>{data.candidateDescription}</p>
            </div>
        )
        document.getElementsByName('candidateName')[0].value = "";
        document.getElementsByName('candidateDescription')[0].value = "";
        console.log(items)
    }
  /*const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });
  const onSubmit = data => {
    console.log('hit');
    action(data);
    console.log(data);
    props.history.push("./InputRegisterDate");
  }*/


  return (
    <div>
        <div>
                <h2>What is the name of this Candidates?</h2>
                    <input 
                    name="candidateName"
                    style={ errors.candidateName && errorStyle}
                    ref={register}/>
                    <input 
                    name="candidateDescription"
                    style={ errors.candidateDescription && errorStyle}
                    ref={register}/>
                    <button onClick={handleSubmit(addUser)}>Add Candidate</button>
                    <button onClick={() => submit()}>Submit Candidates</button>
        </div>
        <div>
            {page_elements}
        </div>
    </div>
  );
};

const errorStyle = {
    borderColor: 'red',
    borderStyle: 'solid',
}

export default InputCandidates;