import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from "react-router-dom";


import InputDate from "./inputs/InputDate"
import InputRegisterDate from "./inputs/InputRegisterDate"
import InputJurisdiction from './inputs/InputJurisdiction'
import InputTitle from './inputs/InputTitle'
import YourSubmission from './inputs/YourSubmission';
import ElectOrProp from './inputs/ElectOrProp';
import InputProposal from './inputs/InputProposal';
import InputCandidates from './inputs/InputCandidates';









export default class Inputinfo2 extends Component {

    constructor() {
        super()
        this.state = {
            logged_in: false
        }
    }

    componentWillMount() {
        fetch('/api/isLoggedIn')
        .then((res) => res.json())
        .then((res) => {
            if (res['logged_in'] === undefined || res['logged_in'] === false) {
                window.location = '/Login'
            }
            else {
                this.setState({logged_in: true})
            }
        })
    }
    render() {
        if (this.state.logged_in === false) {
            return (
                <p>please log in before making a submission</p>
            )
        }
        else {
            return (
                <div>
                    <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
                        <Router>
                        <Route exact path="/Inputinfo2" component={InputDate} />
                        <Route path="/InputRegisterDate" component={InputRegisterDate} />    
                        <Route path="/InputJurisdiction" component={InputJurisdiction} />         
                        <Route path="/InputTitle" component={InputTitle} />   
                        <Route path="/YourSubmission" component={YourSubmission} />            
                        <Route path="/ElectOrProp" component={ElectOrProp} />   
                        <Route path="/InputProposal" component={InputProposal} />   
                        <Route path="/InputCandidates" component={InputCandidates} />   
                        </Router>
                    </div>
                    {/*
        
                    <form onSubmit={handleSubmit(onSubmit)}>
                    <h2>What date does this election take place on?</h2>
                        <input 
                        name="electionDate"
                        style={ errors.electionDate && errorStyle}
                        ref={register({
                        })}/>
        
        
                        <h2>What is the jurisdiction of the election?</h2>
                        <input 
                        name="jurisdiction" 
                        style={ errors.jurisdiction && errorStyle}
                        type={'text'} 
                        ref={register}/>
        
                        <h2>What is the area of the election?</h2>
                        <input 
                        name="area" 
                        style={ errors.area && errorStyle}
                        type={'text'} 
                        ref={register}/>
        
                        <h2>What is the last day to register to vote for the election?</h2>
                        <input 
                        name="lastDay" 
                        style={ errors.lastDay && errorStyle}
                        type={'text'} 
                        ref={register}/>
        
                        <h2>What is the title of the election?</h2>
                        <input 
                        name="title"
                        style={ errors.title && errorStyle}
                        type={'text'} 
                        ref={register}/>
                        <br></br>
                        <button className="lookUpButton" type="submit">Submit</button>
                    </form>
                    */}
                </div>
            )
        }
    }
}
