import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import { chooseJurisdiction, chooseArea } from './rootSlice';





const schema = yup.object().shape({
    jurisdiction: yup.string().required(),


  });

const InputJurisdiction = props => {

    const dispatch = useDispatch()
    const history = useHistory()
    const inputJurisdictionData = useSelector(state => state.jurisdiction)
    

    const { register, handleSubmit, errors} = useForm({
      defaultValues: {inputJurisdictionData},
      resolver: yupResolver(schema),
      reValidateMode: 'onChange'
    });

    const onSubmit = (data) => {
        dispatch(chooseJurisdiction(data.jurisdiction))
        dispatch(chooseArea(data.area))
        history.push("./InputTitle")
        console.log(data)
    }
  /*const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });
  const onSubmit = data => {
    console.log('hit');
    action(data);
    console.log(data);
    props.history.push("./InputRegisterDate");
  }*/


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
            <h2>What type of jurisdiction is this?</h2>
            <select
                            name="jurisdiction"
                            style={ errors.jurisdiction && errorStyle}
                            ref={register}>
            <option>
              Country
            </option>
            <option>
              State
            </option>
            <option>
              County
            </option>
            <option>
              City
            </option>
            <option>
              sldl
            </option>
            <option>
              sldu
            </option>
            <option>
              borough
            </option>
            <option>
              Circuit Court
            </option>
            <option>
              Council District
            </option>
            <option>
              Supreme Court
            </option>


            </select>

            <h2>What area does this election fall under?</h2>
                <input 
                name="area"
                style={ errors.area && errorStyle}
                ref={register}>
                </input>
                <input type="submit"/>
    </form>
  );
};

const errorStyle = {
    borderColor: 'red',
    borderStyle: 'solid',
}

export default InputJurisdiction;