import React, { Component } from 'react'
import { Transition, animated } from 'react-spring/renderprops'

export default class Submissions extends Component {

    constructor() {
        super()
        this.state = {
            submissions: [],
            page_elements: [],
            submission: 0,
            show: true,
            key: 0,
            logged_in: false
        }
    }

    componentWillMount() {
        fetch('/api/isLoggedIn')
        .then((res) => res.json())
        .then((res) => {
            if (res['logged_in'] !== undefined && res['logged_in'] === true && (res['level'] === 'admin' || res['level'] === 'moderator')) {
                this.setState({logged_in: true}) 
            }
            else {
                window.location = '/DateHolder'
            }
        })
    }

    parseDate(date) {
        var date_arr = date.split('-')
        var year = date_arr[0];
        var month = date_arr[1];
        var day = date_arr[2].split('T')[0]
        var date_str = month + '/' + day + '/' + year;
        return (date_str);
    }

    judgement(answer, item) {
        var JSON_Response = {
            "answer":answer,
            "item":this.state.submissions.submissions[item]
        }
        JSON_Response.item.JSON_Data = JSON.parse(JSON_Response.item.JSON_Data);
        console.log(JSON_Response)

        if (true) {
            fetch('/api/moderate/', {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(JSON_Response)
            })
            .then(res => res.json())
            .then(reply => console.log(reply))
        }
    }

    printSubmissions() {
        this.setState({key: this.state.key + 1})
        console.log(this.state.key)
        var items = this.state.submissions.submissions;
        if (items === undefined || items === null) {
            return;
        }
        var return_elements = [];
        var tempJSON = {};
        var candyProps = []
        var inlineStyle = {
            display: 'inline-block',
            marginBottom: '0px'
        }
        console.log(items.length)
        if (items.length === 0) {
            var returnElement = []
            this.setState({key: this.state.key + 1})
            returnElement.push(
                <div key={this.state.key}>
                    <h2 style={{color: 'black'}}>No more submissions!</h2>
                </div>
            )
            this.setState({page_elements: returnElement})
            return
        }
        for (var i=0; i < items.length; i++) {
            /*if (items[i].) {
                break;
            }*/
            console.log(i)
            const temp = i;
            // building candidate/proposal data
            console.log(items)
            try {
                tempJSON = JSON.parse(items[i].JSON_Data);
                if (tempJSON.type === "candidates") {
                    var candyBag = []
                    for (var candyGuy in tempJSON.items) {
                        candyBag.push(
                            <div style={{textIndent: '2vw'}} key={candyGuy}>
                                <h4 style={inlineStyle}>Name:</h4>
                                <> {tempJSON.items[candyGuy].name}</>
                            </div>,
                            <div style={{textIndent: '4vw'}} key={candyGuy+100}>
                                <h4 style={inlineStyle}>Party:</h4>
                                <> {tempJSON.items[candyGuy].party}</>
                            </div>,
                            <hr style={{marginLeft: '3vw'}}></hr>
                        )
                    }
                    candyProps = [
                        <div>
                            <h3 style={inlineStyle}>Candidates: </h3>
                            {candyBag}
                        </div>
                    ]
                }
                else {
                    candyProps = [
                        <div>
                            <h3 style={inlineStyle}>Proposal Description: </h3>
                            {tempJSON.description}
                            <hr></hr>
                        </div>
                    ]
                }
                return_elements.push(
                    <div className="approval"  key={this.state.key}>
                        <h2>
                            Does this sumbission seem accurate?
                        </h2>

                        <div className="submissionHolder">
                            <div>
                                <h3 style={inlineStyle}>Election Date: </h3>{this.parseDate(items[i].Election_Date)}
                            </div>
                            <hr></hr>
                            <div>
                                <h3 style={inlineStyle}>Last Day to Register: </h3>{this.parseDate(items[i].Register_Date)}
                            </div>
                            <hr></hr>
                            <div>
                                <h3 style={inlineStyle}>Jurisdiction: </h3>{items[i].Jurisdiction}
                            </div>
                            <hr></hr>
                            <div>
                                <h3 style={inlineStyle}>Area: </h3>{items[i].Area}
                            </div>
                            <hr></hr>
                            <div>
                                <h3 style={inlineStyle}>Title: </h3>{items[i].Title}
                            </div>
                            <hr></hr>
                            {candyProps[0]}
                        </div>
                        <button className="approve" id={'approve_' + i.toString()} onClick={() => {
                            this.judgement(true, temp)
                            this.refreshSubmission()
                        }}>
                            Approve
                        </button>
                        <button className="skip" id={'skip_' + i.toString()} onClick={() => {
                            console.log('skip');
                            this.nextSubmission()
                        }}>
                            Skip
                        </button>
                        <button className="deny" id={'deny_' + i.toString()} onClick={() => {
                            this.judgement(false, temp)
                            this.refreshSubmission()
                        }}>
                            Deny
                        </button>
                    </div>
                )
            } catch (error) {
                
            }
            

            // pushing all data
           
            /*var x = document.getElementById('approve_0')
            x.onlick = function(e){
            }*/
        }
        this.setState({page_elements: return_elements})
    }

    nextSubmission = () => {
        this.setState({submission: this.state.submission + 1})
        var n = this.state.submission
        console.log(n)
        fetch('/api/submissions/' + n.toString())
        .then(res => res.json())
        .then(submissions => this.setState({submissions}))
        .then(() => this.printSubmissions());
    }

    refreshSubmission = () => {
        var n = this.state.submission
        console.log(n)
        fetch('/api/submissions/' + n.toString())
        .then(res => res.json())
        .then(submissions => this.setState({submissions}))
        .then(() => this.printSubmissions());
    }

    componentDidMount() {
        this.nextSubmission()
    }

    render() {
        if (this.state.logged_in === false) {
            return(<p>only moderators can view this page</p>)
        }
        else {
            return (

                <div>
                    <h1 onClick={this.toggle}>
                        App Moderation
                    </h1>
                    <div className="submissionHolder" id="main-shit"> 
                            <Transition
                                native
                                reset
                                unique
                                items={this.state.key}
                                from={{ opacity: 0, transform: 'translate3d(100%,0,0)',position: "absolute"}}
                                enter={{ opacity: 1, transform: 'translate3d(0,0,0)',position: "relative" }}
                                leave={{ opacity: 0, transform: 'translate3d(-100%,0,0)',position: "absolute" }}>
                                {() => (props => <animated.div style={props}> {this.state.page_elements} </animated.div>)}
                            </Transition>
                    </div>
                </div>
            )
        }
    }
}
