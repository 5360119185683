import React from 'react'

export default function ReviewUsers() {
    return (
        <div className="reviewUsers">

            <div className="approvalHolder">            
            <span>Username</span>
            <span>Permissions</span>
            <span>Desired Permissions</span>
            <span>
                <button>Approve</button>
                <button>Deny</button>

            </span>

            </div>

            <div className="usersHolder">            
            <span>Username</span>
            <span>Permissions</span>
            <span>Desired Permissions</span>
            <span>
                <select >
                <option value="Change Permissions">Change Permissions...</option>
                <option value="User">User</option>
                <option value="Moderator">Moderator</option>
                <option value="Admin">Admin</option>
                </select>

                <button>Approve</button>

            </span>

            </div>
            
        </div>
    )
}
