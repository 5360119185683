import React from 'react'
import { useForm } from "react-hook-form";


export default function Register() {

    const { register, handleSubmit } = useForm();
    /*const onSubmit = data => {
        fetch('/api/login/', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        .then((res) => res.json())
        .then((result) => {
            console.log(result)
        })
    };*/
    const onSubmit2 = data => {
        fetch('/api/create_login/', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        .then((res) => res.json())
        .then((result) => {
            console.log(result)
            if (result.success === true)
                window.location = '/login'
            else 
                document.getElementById("ucandeleteme2").innerText = "Sorry, username is already taken"
        })
    };

    return (
        <div>
            
            <div className="login-Holder">

            <h1>create account</h1>

            <form onSubmit={handleSubmit(onSubmit2)}>

            <input name="create_username" placeholder="username" ref={register} />
            <input name="create_password" placeholder="password" type="password" ref={register} />
            <input type="submit" />


            </form>
            <p id={'ucandeleteme2'}>enter username and password</p>
            </div>
            
        </div>
    )
}