import React from 'react'
import { Link } from 'react-router-dom'
import mark from './img/mark.png'


export default function Header() {



    /*var logout = () => {
        fetch('/api/logout', {
            method:"GET"
        })
        .then((response) => {
            console.log(response)
        })
        .then(() => {
            window.location = '/'
        })
    }

        fetch('/api/isLoggedIn',{method:"GET"})
        .then((res) => res = res.json())
        .then((res) => {
            console.log(res.username)
            if (res.username !== undefined) {
                document.getElementById("nameNstuff").style.display = "unset"
                document.getElementById("loginRegister").style.display = "none"
                document.getElementById('name').innerText = res.username
                document.getElementById('level').innerText = res.level
            }
            else {
                document.getElementById("nameNstuff").style.display = "none"
                document.getElementById("loginRegister").style.display = "unset"
            }
            console.log(res)
        });
*/
    return (
        <div className="mainHeader">
            
            <div> 
            <Link to="/DateHolder">
                <img className="logo" src={mark} alt="Public Ballot Logo"/>
                </Link>
                 </div>

            <div className="mainNav">

                <div className="mainLinks" style={{color: "black"}}>
                    <Link style={{color: "black"}} to="/DateHolder">Home</Link>
                                    </div> 
                {/*<div className="loginRegister" id="loginRegister" style={{color: "black", display: "none"}}>
                    <Link style={{color: "black"}} to="/Login">Login</Link> | <Link style={{color: "black"}} to="/Register">Register</Link>
                </div> 

                <div className="loginRegister" id="nameNstuff" style={{color: "black", display: "none"}}>
                    <div style={{color: "black", display: "inline"}} id="name"></div> | <div style={{color: "black", display: "inline"}} id="level"></div> | <a style={{color: "black"}} href="#" onClick={() => logout()}>Logout</a>
    </div> */}

                </div>


        </div>
    )
}
