import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import { chooseTitle } from './rootSlice';





const schema = yup.object().shape({
    title: yup.string().required(),


  });

const InputTitle = props => {

    const dispatch = useDispatch()
    const history = useHistory()
    const inputTitleData = useSelector(state => state.title)
    

    const { register, handleSubmit, errors} = useForm({
      defaultValues: {inputTitleData},
      resolver: yupResolver(schema),
      reValidateMode: 'onChange'
    });

    const onSubmit = (data) => {
        dispatch(chooseTitle(data.title))
        history.push("./ElectOrProp")
        console.log(data)
    }
  /*const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });
  const onSubmit = data => {
    console.log('hit');
    action(data);
    console.log(data);
    props.history.push("./InputRegisterDate");
  }*/


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
            <h2>What is the title of this race?</h2>
                <input 
                name="title"
                style={ errors.title && errorStyle}
                ref={register}/>
                <input type="submit"/>
    </form>
  );
};

const errorStyle = {
    borderColor: 'red',
    borderStyle: 'solid',
}

export default InputTitle;