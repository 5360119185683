import React, {Component} from 'react';
import './DateHolder.js';
import { Link } from 'react-router-dom'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { Spring } from 'react-spring/renderprops';
import map from './img/currentmap.png'
import { animated } from 'react-spring';


class DateHolder extends Component {
    constructor() {
        super();
        this.state = {
            dates: [],
            toggle: true,
            electies: [],
            jur_counts: []
        }
    }

    /*
    componentDidMount() {
        fetch('/api/dates/')
            .then(res => res.json())
            .then(dates => this.setState({dates}, () => console.log('Customers fetched...', dates)));
    }

    changeQuery() {
        console.log('this chagnes query')
        var my_query = document.getElementById('new_query');
        if (my_query != null) {
            console.log('/api/dates/' + my_query.value);
            fetch('/api/dates/' + my_query.value)
                .then(res => res.json())
                .then(dates => this.setState({dates}));
        }
    }*/
    componentDidMount() {
    }

    /*changeQuery() {
        console.log('this chagnes query')
        var my_query = document.getElementById('new_query');
        if (my_query != null) {
            fetch('https://www.googleapis.com/civicinfo/v2/representatives?key=AIzaSyAOdJ_JhOduEBLUe-zJhCzODGn2Uzqzbsc&includeOffices=false&address=2093%20Cameo%20Dr')
                .then(res => res.json())
                .then(dates => this.setState({dates}));
                //.then(dates => fetch('/api/dates/' + dates))
        }
    }*/


    buttonPress(){
        this.changeQuery()
        this.removeTop()

    }

    removeTop(){
        if (this.state.toggle === true){
            this.setState({toggle: false})
            console.log(this.state.toggle)

        }
    }

    changeQuery() {
        var my_query = document.getElementById('react-google-places-autocomplete-input');
        if (my_query != null) {
            fetch('/api/dates/' + my_query.value)
                .then(res => res.json())
                .then(dates => {
                    this.setState({dates})
                    var query2 = {}
                    var d
                    query2["address"] = dates["data"]
                    for (d in dates["dates"]) {
                        query2["name"] = dates["dates"][d]
                        var new_counts
                        fetch('/api/customers/' + JSON.stringify(query2))
                            .then(res => res.json())
                            .then(electies => this.setState({electies}))
                            .then(() => console.log(this.state.electies.electies))
                            .then(() => {
                                new_counts = {"total":0}
                                var temp_electies = this.state.electies.electies
                                var e
                                for (e in temp_electies) {
                                    if (!(temp_electies[e].Jurisdiction in new_counts)) {
                                        new_counts[temp_electies[e].Jurisdiction] = 1
                                    }
                                    else {
                                        new_counts[temp_electies[e].Jurisdiction] += 1
                                    }
                                    new_counts["total"] += 1
                                }
                            }).then(() => {
                                var jur_counts = this.state.jur_counts
                                jur_counts.push(new_counts)
                                this.setState(jur_counts)
                                console.log("jur_counts")
                                console.log(this.state.jur_counts)
                            })
                    }
                });
               //.then(dates => fetch('/api/dates/' + dates))
        }

    }

    printElections(electies, jur_counts) {
        var months = ["January","February","March","April","May","June","July","August","September","October","November","Devember"]
        var jurisdictions = {
            "country":"United States",
            "state":"State",
            "district_court": "District Court",
            "council_district": "Council District",
            "sldl": "State House district",
            "county": "County",
            "place": "Place",
            "sldu": "State Senate district",
            "cd": "Congressional district"
        }
        var items = [];
        var plural
        var jurs
        var comma
        for (var i = 0; i < electies.length; i++) {
            jurs = [];
            for (var j=0; j < electies[i].jurisdiction.length; j++) {
                if (!jur_counts[i]) {
                    break;
                }
                plural = "election"
                if (jur_counts[i][electies[i].jurisdiction[j]] > 1) {
                    plural = "elections"
                }
                if (j != electies[i].jurisdiction.length - 1) {
                    comma = ","
                }
                else {
                    comma = ""
                }
                jurs.push(
                <div>({jur_counts[i][electies[i].jurisdiction[j]]}) {jurisdictions[electies[i].jurisdiction[j]]} {plural}{comma} </div>
                )
            }
            if (jur_counts[i]) {
                items.push(
                <Link key={i} to={{
                        pathname:"/Elections",
                        state:{
                            address: this.state.dates.data,
                            name: electies[i]
                        }
                    }}
                    >
                    <div className="date-Holder">
                        <div><h2>{jur_counts[i]["total"]} Elections on {months[electies[i].election_date.month -1]} {electies[i].election_date.date}, {electies[i].election_date.year}</h2></div>
                        {jurs}
                    </div>
                </Link>
                )
            }
        }
        return(items)
    }

    render() {
        if (this.state.dates == null || this.state.dates === [] || this.state.dates.dates == null) {
            return (
                <div className="App">
                    
                    <animated.div className="welcome">
                        <h2>Welcome to Public Ballot! An election information tool</h2>
                        <h2>Put in your address to find out about upcoming elections in your area:</h2>
                   
                    </animated.div>

                    <div className="addressForm" >
                    
                    

                    <div>
                    <GooglePlacesAutocomplete
                        placeholder="What is your permanent address?"
                        onSelect={(addy) => {
                            this.setState({address: addy.description})

                        }}
                        autocompletionRequest = {{
                            componentRestrictions: {
                                country: ['us'],
                            }
                        }}
                        on
                    />
                    </div>
                    <div>
                    <button className="lookUpButton" onClick={() => this.buttonPress()}>Look Up</button>
                    </div>

                    </div>
                    

                    <div className="about">

                    <div className="aboutText">
                    <h3>Public Ballot is (or will be) a crowd sourced election information tool</h3>
                    <p> We made this app to give people a clear overview of the elections they are eligible to vote in, from a national to local level. The idea is that users will not only find election data here, but submit their own as well, creating a database of valuable election information available to everyone, presented in a way that is quick and easy to understand.</p>
                    <p> We are currently only serving addresses within the state of Texas, as we are currently in the proccess of gathering data for all 50 states. But Texas Residents should be able to get the information they need from our tool. We will update the map as we get more information (:</p>
                    <p> This is an early version! So forgive our mess, hah, but we thougth it was important to make our tool available before the upcoming November 3rd election, as it may be useful to someone out there. We appreciate your interest in our tool, and hope you'll find it useful!</p>
                    <br></br>
                    <p>This whole thing was designed and coded by Jonathan Pinto and Caleb Halter, call it our labour of love. For the moment, contact us at x@jonathanpinto.net</p>
                    </div>

                    <div className="currentMap">
                    <img src={map}></img>
                    </div>

                    </div>


                    
                </div>
            );
        }
        else {
            var pageItems = [];

            if (this.state.dates.data.line1 !== undefined) {
                pageItems.push([
                    <h1>{this.state.dates.data.line1.fulltext}</h1>
                ])

                pageItems.push([
                    <h2>Your jurisdictions:</h2>
                ])

                if (this.state.dates.data.city !== undefined && this.state.dates.data.state !== undefined) {
                    pageItems.push([
                        <div>{this.state.dates.data.city.fulltext}, {this.state.dates.data.state.fulltext}</div>
                    ])
                }
                else if (this.state.dates.data.city !== undefined) {
                    pageItems.push([
                        <div>{this.state.dates.data.city.fulltext}</div>
                    ])
                }
                else if (this.state.dates.data.state !== undefined) {
                    pageItems.push([
                        <div>{this.state.dates.data.state.fulltext}</div>
                    ])
                }

                if (this.state.dates.data.county !== undefined) {
                    pageItems.push([
                        <div>{this.state.dates.data.county.fulltext}</div>
                    ])
                }

                if (this.state.dates.data.place !== undefined) {
                    pageItems.push([
                        <div>{this.state.dates.data.place.fulltext}</div>
                    ])
                }

                pageItems.push([
                    <br></br>
                ])

                if (this.state.dates.data.sldl !== undefined) {
                    pageItems.push([
                        <div>{this.state.dates.data.sldl.fulltext}</div>
                    ])
                }

                if (this.state.dates.data.sldu !== undefined) {
                    pageItems.push([
                        <div>{this.state.dates.data.sldu.fulltext}</div>
                    ])
                }

                if (this.state.dates.data.cd !== undefined) {
                    pageItems.push([
                        <div>{this.state.dates.data.cd.fulltext}</div>
                    ])
                }

                if (this.state.dates.data['district court'] !== undefined) {
                    pageItems.push([
                        <div>{this.state.dates.data['district court'].fulltext}</div>
                    ])
                }

                if (this.state.dates.data['supreme court'] !== undefined) {
                    pageItems.push([
                        <div>{this.state.dates.data['supreme court'].fulltext}</div>
                    ])
                }
            }

            return (
                <div className="App">
                    <div className="everythingHolder">
                    <div className="addressForm" >
                        <GooglePlacesAutocomplete
                            placeholder="What is your permanent address?"
                            onSelect={(addy) => {
                                this.setState({address: addy.description})

                            }}
                            autocompletionRequest = {{
                                componentRestrictions: {
                                    country: ['us'],
                                }
                            }}
                            on
                        />
                        <button className="lookUpButton" onClick={() => this.buttonPress()}>Look Up</button>



                    </div>





                    <Spring
                    from={{ marginTop: -500}}
                    to={{ marginTop: 0}}
                    duration={{duration: 1000}}
                    >
                    
                    {props =>(
                        <div style={props}>

                        <div className="DateTopPart">

                            
                        {pageItems}

                    </div>
                        
                    </div>
                    )}

                    </Spring>


                    <Spring
                    from={{ opacity: 0}}
                    to={{ opacity: 1}}
                    duration={{duration: 1000}}
                    >
                    
                    {props =>(
                        <div style={props}>

                        <div className="yourJurisdictions">
                        {this.printElections(this.state.dates.dates, this.state.jur_counts)}

                    </div>
                    </div>
                    )}

                    </Spring>
                    </div>
                </div>
            );
        }
    }
}

export default DateHolder;
